import React from "react";
import Seo from "../../components/SEO/SEO";
import Layout from "../../components/Layout/Layout";
import { Box, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { graphql, Link, navigate } from "gatsby";

export const query = graphql`
  query Query22($productId: String) {
    allWpCategory(filter: { id: { eq: $productId } }) {
      nodes {
        name
        description
        danh_muc {
          image {
            sourceUrl
          }
        }
        wpChildren {
          nodes {
            name
            id
            slug
            danh_muc {
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  boxImg: {
    position: "relative",
    width: "100%",
    paddingTop: "20%",
    minHeight: 400,
  },
  img: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: 0,
  },
  filter: {
    backgroundColor: "#00000066",
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
  },
  name: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    top: "45%",
  },
  boxName: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    backgroundColor: "#e5edf5",
    fontSize: 20,
    height: 60,
    "&:hover": {
      backgroundColor: "#c4def9",
    },
    padding: "16px 0px",
  },
  widthBoxProduct: {
    [theme.breakpoints.up(1000)]: {
      width: "32%",
    },
    [theme.breakpoints.between(550, 1000)]: {
      width: "49%",
    },
    [theme.breakpoints.down(550)]: {
      width: "100%",
    },
    "&:hover": {
      "& $boxName": {
        backgroundColor: "#c4def9",
      },
      "& $imageHover": {
        backfaceVisibility: "hidden",
        transform: "scale(1.2)",
      },
      "& .about": {
        display: "flex",
        cursor: "pointer",
      },
    },
  },
  heightImgProduct: {
    [theme.breakpoints.up(800)]: {
      height: 330,
    },
    [theme.breakpoints.between(550, 800)]: {
      height: 250,
    },
    [theme.breakpoints.down(550)]: {
      height: 330,
    },
    position: "relative",
  },
  imageHover: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    transition: "all 0.3s",
    transform: "scale(1.02)",
    cursor: "pointer",
  },
}));

const DanhMuc = ({ data }) => {
  const classes = useStyles();
  const breakpointsXs = useMediaQuery("(max-width: 1000px)");
  const category = data.allWpCategory.nodes[0];

  return (
    <Layout>
      <Seo title="Sản phẩm" />
      <Box className={classes.boxImg}>
        <img
          className={classes.img}
          src={category?.danh_muc?.image?.sourceUrl}
          alt=""
        />
        <Box className={classes.filter} />
        <Box
          className={classes.name}
          fontSize={35}
          fontWeight={700}
          color="white"
        >
          {category?.name}
        </Box>
      </Box>
      <Box width="100%" maxWidth={1120} mx="auto" px={2}>
        <Box
          fontSize={18}
          fontWeight={700}
          display="flex"
          flexWrap="wrap"
          color="#551a8b"
          mt={3}
        >
          <Link to="/" style={{ color: "#308632" }}>
            Trang chủ
          </Link>
          &nbsp;<Box style={{ color: "#308632" }}>/</Box>&nbsp;
          <Box color="black">{category?.name}</Box>
        </Box>
        <Box fontSize={34} fontWeight={700} pt={3}>
          Sản phẩm tiêu biểu :
        </Box>
        <Box
          py={breakpointsXs ? 3 : 7}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {category?.wpChildren?.nodes?.map(product => (
            <Box
              onClick={() => navigate(`/san-pham/${product.slug}`)}
              boxShadow={3}
              className={classes.widthBoxProduct}
              mb={3}
            >
              <Box className={classes.heightImgProduct} overflow="hidden">
                <img
                  className={classes.imageHover}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={product?.danh_muc?.image?.sourceUrl}
                />
                <Box
                  position="absolute"
                  top={0}
                  fontSize={30}
                  className="about"
                  display="none"
                  fontWeight={700}
                  width="100%"
                  bgcolor="#00000066"
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                >
                  Xem chi tiết
                </Box>
              </Box>
              <Box className={classes.boxName}>
                <b>{product.name}</b>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Layout>
  );
};

export default DanhMuc;
